import { MEDIA_QUERY_MEDIUM } from "./variables";
import styled from "styled-components";

const PageHeadline = styled.h1`
  color: var(--themeColour);
  margin-top: 3rem;
  font-weight: bold;
  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    margin-top: 4rem;
  }
`;

export default PageHeadline;
